import CampaignGoalsPopup from '@/components/campaign/campaignGoalsPopup/campaignGoalsPopup';
import CampaignFooter from '@/components/campaign/createCampaignFooter';
import CampaignSteps from '@/components/campaign/createCampaignSteps';
import RevenueTrackingEventPopup from '@/components/revenueTrackingEventsPopup/revenueTrackingEventsPopup';
import { getCampaignGoals, updateCampaignGoals } from './../../services/campaignv1';

export default {
  name: 'campaignGoal',
  props: {},
  components: {
    CampaignSteps,
    RevenueTrackingEventPopup,
    CampaignFooter,
    CampaignGoalsPopup
  },
  data() {
    return {
      step: 3,
      fetchingData: true,
      savingCampaign: false,
      hasUnsavedChanges: false,

      campaign: null,
      goalsData: null
    };
  },
  mixins: [],
  watch: {
    data: {
      deep: true,
      handler() {
        this.hasUnsavedChanges = true;
      }
    }
  },

  methods: {
    async fetchDetailsForEdit() {
      try {
        this.fetchingData = true;

        // Fetch Details
        let response = await getCampaignGoals(this.campaignId);

        // Check For Campaign
        this.campaign = response.data.campaign;
        if (!this.campaign) {
          this.$router.replace({ name: '404' });
          return;
        }

        // Render goals template
        this.goalsData = response.data.data;
        this.$refs.goalsPopup.renderContent(this.goalsData);

        this.fetchingData = false;
      } catch (err) {
        this.reportError(err);
        this.errorToast('Failed to fetch goals. Please contact support.');
        this.fetchingData = false;
      }
    },

    async validateAndSave() {
      try {
        this.savingCampaign = true;

        // Validare first.
        let isValid = await this.$refs.goalsPopup.validateForm();
        if (!isValid) return;

        // Read Processed Content
        let data = this.$refs.goalsPopup.readProcessedContent();

        console.log('save params', data);
        await updateCampaignGoals(this.campaignId, data);

        this.savingCampaign = false;

        return true;
      } catch (err) {
        this.reportError(err);
        this.errorToast('Failed to save changes. Please contact support.');
        this.savingCampaign = false;
      }
    },

    async onSaveDraft() {
      let isSaved = await this.validateAndSave();
      if (isSaved) {
        this.successToast('Draft Saved.');
      }
    },

    async onGoBack() {
      // Check for changes first.
      if (this.hasUnsavedChanges) {
        try {
          let result = await this.$confirm('You have unsaved changes, save and proceed?', 'Save Changes?', {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Save Changes',
            cancelButtonText: 'Ignore Changes'
          });

          // Save changes
          result = await this.validateAndSave();
          if (!result) return;
        } catch (err) {
          // Igore changes
        }
      }

      // Go To Previous
      this.$router.push(`/campaign/update/${this.campaign.channel}/${this.campaignId}`);
    },

    async onGoNext() {
      let result = await this.validateAndSave();
      if (result) {
        // Redirect to targetAudiancePage
        this.savingCampaign = true;
        this.$router.push(`/campaign/update/review/${this.campaignId}`);
      }
    }

    //#endregion -----
  },

  mounted() {
    this.campaignId = this.$route.params.id;

    if (this.campaignId == null) {
      // Redirect to 404
      this.$router.replace('/404');
      return;
    }
    this.fetchDetailsForEdit();
  }
};
