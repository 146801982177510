<template>
  <div id="campaignGoals">
    <div class="loadingDiv" v-if="fetchingData" v-loading="true" style="min-height: 300px"></div>

    <template>
      <!-- Campaign Steps -->
      <CampaignSteps v-if="campaign" :step="step" :campaign="campaign"></CampaignSteps>

      <CampaignGoalsPopup ref="goalsPopup"></CampaignGoalsPopup>

      <!-- Step Footer -->
      <CampaignFooter :step="step" v-if="campaign" :campaign="campaign" @onNext="onGoNext" @onPrev="onGoBack" @onSaveDraft="onSaveDraft"></CampaignFooter>
    </template>

    <!-- Revenue Tracking Popup -->
    <RevenueTrackingEventPopup ref="revenueTrackingEventPopup" @changedRevenueTracking="fetchDetailsForEdit"></RevenueTrackingEventPopup>
  </div>
</template>
<style lang="scss" src="./campaignGoal.scss"></style>

<script>
import campaignGoalComponent from './campaignGoalComponent';
export default campaignGoalComponent;
</script>
